import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Helmet from 'react-helmet'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'

import { Footer, GoogleAnalytics, Header } from './components'

import activeLanguages from './constants/languages'

import 'animate.css/animate.css'
import './index.scss'

const Layout = () => {
  const { urlLang } = useParams()
  const { i18n } = useTranslation()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const { changeLanguage } = i18n

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  useEffect(() => {
    if (Object.keys(activeLanguages).includes(urlLang)) {
      changeLanguage(urlLang)

      document.documentElement.lang = urlLang
    } else {
      navigate(`/en${window.location.pathname}`, { replace: true })
    }
  }, [urlLang])

  return (
    <main>
      <Helmet>
        <script
          src={`https://cdn.cookielaw.org/consent/${process.env.REACT_APP_ONETRUST_KEY}/OtAutoBlock.js`}
          type="text/javascript"
        >
        </script>

        <script
          data-domain-script={process.env.REACT_APP_ONETRUST_KEY}
          onLoad="function OptanonWrapper() {}"
          src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
          type="text/javascript"
        >
        </script>
      </Helmet>
      <Header />

      <div className="main-content-with-footer">
        <div className="main-content">
          <div className="flex-row">
            <div className="col-12 no-padding">
              <Outlet />
            </div>
          </div>
        </div>

        <GoogleAnalytics />
        <Footer />
      </div>
    </main>
  )
}

export default Layout
